import * as React from "react";
import repository from "client/repository";
import {
    DataTable,
    DataTableBody,
    DataTableRow,
    DataTableRowColumn,
    DataTableRowHeaderColumn, DataTableRowWithActionColumn
} from "components/DataTable";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import PaperLayout from "components/PaperLayout";
import {default as OverflowMenu} from "components/Menu/OverflowMenu";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import FormSectionHeading from "components/form/Sections/FormSectionHeading";
import ActionList from "components/ActionList/ActionList";
import {InternalRedirect} from "components/Navigation/InternalRedirect";
import {hubsRouteLinks} from "./hubsRouteLinks";
import {HubResource} from "../../client/resources/hubResource";
import routeLinks from "../../routeLinks";
import InternalLink from "../../components/Navigation/InternalLink/InternalLink";
import OpenDialogButton from "../../components/Dialog/OpenDialogButton";
import ChangeAzureAdConfigurationDialog from "./ChangeAzureAdConfigurationDialog";
import ChangeHubApiKeyDialog from "./ChangeHubApiKeyDialog";
import ChangeHubOidcIdentityDialog from "./ChangeHubOidcIdentityDialog";
import {DeleteConfirmation} from "../instances/Instances/DeleteConfirmation";
import { linkToInstance } from "../instances/linkToInstance";
import { HostedInstanceResource } from "../../client/resources/hostedInstanceResource";
import { ReefResource } from "../../client/resources/reefResource";
import ExternalLink from "../../components/Navigation/ExternalLink";
import { CheckHubAccessResource } from "../../client/resources/checkHubAccessResource"

interface State extends DataBaseComponentState {
    redirectTo?: string;
    reefs: Map<string, ReefResource>;
    checkResult?: CheckHubAccessResource;
    canDelete: boolean;
}

interface Props {
    hub: HubResource;
    breadcrumbTitle: string;
    updateHub: (hub: Partial<HubResource>) => void;
}

export class HubDetail extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            reefs: new Map<string, ReefResource>(),
            checkResult: null,
            canDelete: false
        };
    }

    componentDidMount() {
        return this.doBusyTask(async () => {
            this.setState({
                reefs: await repository.Reefs.map(),
                checkResult: await repository.Hubs.checkAccess(this.props.hub.Id)
            });
        });
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo}/>;
        }

        const hub = this.props.hub;

        return <PaperLayout busy={this.state.busy}
                            errors={this.state.errors}
                            title="Detail"
                            breadcrumbTitle={this.props.breadcrumbTitle}
                            sectionControl={<ActionList actions={hub ? this.getActions() : []}/>}>
            {hub && this.getBody()}
        </PaperLayout>;
    }

    private getActions() {
        const menuItems = [
            this.props.hub.HubInstance && OverflowMenu.dialogItem("Reconfigure", this.renderReconfigureDialog()),
            this.props.hub.HubInstance && OverflowMenu.dialogItem("Add Support User", this.renderSupportUserDialog()),
            OverflowMenu.deleteItem(
                "Delete", 
                "Are you sure you want to delete this hub?", 
                () => this.deleteHub(),
                <DeleteConfirmation itemName={this.props.hub ? this.props.hub.DisplayName : ""} deleteWhat="hub"
                                    onChange={canDelete => this.setState({canDelete})}/>, 
                !this.state.canDelete),
        ];

        return [
            <OverflowMenu menuItems={menuItems}/>
        ];
    }

    private getBody() {
        const hub = this.props.hub;
        const reef = hub.HubInstance ? this.getReefFor(hub.HubInstance) : null;
        return hub && <>
            <FormSectionHeading title="General"/>
            <DataTable>
                <DataTableBody>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Name</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{hub.DisplayName}</DataTableRowColumn>
                    </DataTableRow>
                    {hub.HubInstance && <>
                        <DataTableRow>
                            <DataTableRowHeaderColumn>Hub instance</DataTableRowHeaderColumn>
                            <DataTableRowColumn><InternalLink to={routeLinks.instances.instance(hub.HubInstance.Id).root}>{hub.HubInstance.Id}</InternalLink></DataTableRowColumn>
                        </DataTableRow>
                        {reef && <DataTableRow>
                            <DataTableRowHeaderColumn>Hub URL</DataTableRowHeaderColumn>
                            <DataTableRowColumn>
                                {linkToInstance(hub.HubInstance, reef)}
                            </DataTableRowColumn>
                        </DataTableRow>}
                        <DataTableRow>
                            <DataTableRowHeaderColumn>Azure AD client ID</DataTableRowHeaderColumn>
                            <DataTableRowWithActionColumn action={<OpenDialogButton label={"Change Azure AD Configuration"}>
                                <ChangeAzureAdConfigurationDialog hub={hub}
                                                                  onChange={this.props.updateHub}/>
                                </OpenDialogButton>}>
                                {hub.AzureAdClientId ?? "Not set"}
                            </DataTableRowWithActionColumn>
                        </DataTableRow>
                        
                    </>}
                    {hub.HubUrl &&
                        <DataTableRow>
                            <DataTableRowHeaderColumn>Hub URL</DataTableRowHeaderColumn>
                            <DataTableRowWithActionColumn action={<OpenDialogButton label={"Change API key"}>
                                <ChangeHubApiKeyDialog hub={hub} onChange={this.props.updateHub}/>
                            </OpenDialogButton>}>
                                <ExternalLink href={hub.HubUrl}>{hub.HubUrl}</ExternalLink>
                            </DataTableRowWithActionColumn>
                        </DataTableRow> }
                </DataTableBody>
            </DataTable>

            <FormSectionHeading title="Authentication &amp; Authorization"/>
            <DataTable>
                {hub.HubUrl && <DataTableBody><DataTableRow>
                        <DataTableRowHeaderColumn>OIDC Audience</DataTableRowHeaderColumn>
                        <DataTableRowWithActionColumn action={<OpenDialogButton label={"Change OIDC identity"}>
                            <ChangeHubOidcIdentityDialog hub={hub} onChange={this.props.updateHub}/>
                        </OpenDialogButton>}>
                            {hub.OidcIdentityAudience}
                        </DataTableRowWithActionColumn>
                   </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>OIDC External ID</DataTableRowHeaderColumn>
                        <DataTableRowColumn>
                            {hub.OidcIdentityExternalId}
                        </DataTableRowColumn>
                    </DataTableRow>
                </DataTableBody>}
                <DataTableBody>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Authenticated As</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{this.state.checkResult ? <ExternalLink href={this.state.checkResult.UserUri}>{this.state.checkResult.UserName}</ExternalLink>  : "Checking..."}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Visible Spaces</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{this.state.checkResult ? this.state.checkResult.VisibleSpaces.map((n) => <div>{n}</div>) : <div>&nbsp;</div>}</DataTableRowColumn>
                    </DataTableRow>
                </DataTableBody>
            </DataTable>
        </>;
    }
    
    private deleteHub = async (): Promise<boolean> => {
        await repository.Hubs.remove(this.props.hub);
        this.setState({redirectTo: hubsRouteLinks.root});

        return true;
    }

    private renderSupportUserDialog() {
        return <SaveDialogLayout title={"Add Support User"}
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 saveButtonLabel={"Yes"}
                                 cancelButtonLabel={"No"}
                                 onSaveClick={() => this.addSupportUser()}>
            <p>Add yourself as a support user on this Hub?</p>
        </SaveDialogLayout>;
    }

    private addSupportUser() : Promise<boolean> {
        return this.doBusyTask(async () => {
            await repository.Hubs.provisionSupportUser(this.props.hub.Id);
            this.setState({redirectTo: hubsRouteLinks.hub(this.props.hub.Id).tasks});

            return true;
        })
    }

    private renderReconfigureDialog() {
        return <SaveDialogLayout title="Reconfigure Hub"
                                 saveButtonLabel="Reconfigure"
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 onSaveClick={this.reconfigure}>
            <p> Reconfigure the hub without any changes? </p>
        </SaveDialogLayout>;
    }

    private reconfigure = (): Promise<boolean> => {
        return this.doBusyTask(async () => {
            await repository.Hubs.reconfigure(this.props.hub);
            this.setState({redirectTo: hubsRouteLinks.hub(this.props.hub.Id).tasks});

            return true;
        });
    }

    private getReefFor(instance: HostedInstanceResource) {
        return this.state.reefs.get(instance.ReefId);
    }
}

export default HubDetail;
