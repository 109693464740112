import * as React from "react";
import repository from "client/repository";
import {
    DataTable,
    DataTableBody,
    DataTableRow,
    DataTableRowColumn,
    DataTableRowHeaderColumn
} from "components/DataTable";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import PaperLayout from "components/PaperLayout";
import FormSectionHeading from "components/form/Sections/FormSectionHeading";
import ActionList from "components/ActionList/ActionList";
import {DynamicWorkerServiceResource} from "../../client/resources/dynamicWorkerServiceResource";
import {
    DynamicWorkerServiceRuntimeStatusResource
} from "../../client/resources/dynamicWorkerServiceRuntimeStatusResource";
import {RouteComponentProps} from "react-router-dom";
import ExternalLink from "../../components/Navigation/ExternalLink";
import externalSystemLinks from "../../externalSystemLinks";
import OverflowMenu from "../../components/Menu/OverflowMenu";
import {dynamicWorkerServicesRouteLinks} from "areas/dynamicWorkerServices/dynamicWorkerServicesRouteLinks";
import buildDynamicWorkerServiceBreadcrumbTitle from "./buildDynamicWorkerServiceBreadcrumbTitle";

interface State extends DataBaseComponentState {
    dynamicWorkerService?: DynamicWorkerServiceResource;
    runtimeStatus?: DynamicWorkerServiceRuntimeStatusResource;
}

type Props = RouteComponentProps<{ id: string }>;

export class DynamicWorkerServiceDetail extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        const id = this.props.match.params.id;

        const dynamicWorkerService =await repository.DynamicWorkerServices.get(id);

        this.setState({
            dynamicWorkerService
        });

        // ToDo: We should store `InstanceId` in the database so we don't have to even perform this call here.
        try {
            const runtimeStatus = await repository.DynamicWorkerServices.getRuntimeStatus(id)

            this.setState({
                runtimeStatus
            });
        }
        catch {
            // Ignore, we are making a call to a live system here so it might fail
        }
    }

    render() {

        const id = this.props.match.params.id;
        
        return <PaperLayout busy={this.state.busy}
                            errors={this.state.errors}
                            title="Detail"
                            breadcrumbTitle={buildDynamicWorkerServiceBreadcrumbTitle(this.state.dynamicWorkerService)}
                            sectionControl={<ActionList actions={this.getActions()}/>}>
            {this.state.dynamicWorkerService && this.getBody()}
        </PaperLayout>;
    }

    private getActions() {
        if (!this.state.dynamicWorkerService) return [];
        
        return [
            this.state.runtimeStatus && <ExternalLink
                href={externalSystemLinks.seq.dynamicWorkerService(this.state.runtimeStatus)}>
                Seq
            </ExternalLink>,
            this.state.runtimeStatus && <ExternalLink
                href={externalSystemLinks.sumoLogic.dynamicWorkerServiceLogs(this.state.runtimeStatus)}>
                SumoLogic Logs
            </ExternalLink>,
            <ExternalLink
                href={this.state.dynamicWorkerService.Url}>
                Status
            </ExternalLink>,
            this.state.runtimeStatus && <ExternalLink 
                href={externalSystemLinks.seq.dynamicWorkerInitialLeaseTime(this.state.runtimeStatus)}>
                Initial Lease Time logs
            </ExternalLink>,
            <OverflowMenu menuItems={[OverflowMenu.navItem("Edit", dynamicWorkerServicesRouteLinks.service(this.state.dynamicWorkerService.Id).edit)]} />
        ];
    }

    private getBody() {
        const dynamicWorkerService = this.state.dynamicWorkerService;
        const runtimeStatus = this.state.runtimeStatus;
        return dynamicWorkerService && <>
            <FormSectionHeading title="General"/>
            <DataTable>
                <DataTableBody>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Id</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{dynamicWorkerService.Id}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Name</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{dynamicWorkerService.Name}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Url</DataTableRowHeaderColumn>
                        <DataTableRowColumn><ExternalLink href={dynamicWorkerService.Url}>{dynamicWorkerService.Url}</ExternalLink> </DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Username</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{dynamicWorkerService.Username}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>SeqDashboardName</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{dynamicWorkerService.SeqDashboardName}</DataTableRowColumn>
                    </DataTableRow>
                </DataTableBody>
            </DataTable>
        </>;
    }
}

export default DynamicWorkerServiceDetail;
