import * as React from "react";
import {BooleanRadioButton, BooleanRadioButtonGroup} from "@octopusdeploy/design-system-components";
import Summary from "components/form/Sections/Summary";
import ExpandableFormSection from "components/form/Sections/ExpandableFormSection";
import FormPaperLayout from "components/FormPaperLayout/FormPaperLayout";
import OptionalFormBaseComponent, {OptionalFormBaseComponentState} from "components/form/FormBaseComponent";
import repository from "client/repository";
import {InternalRedirect} from "components/Navigation/InternalRedirect";
import {cloneDeep} from "lodash";
import FormSectionHeading from "components/form/Sections/FormSectionHeading";
import {RouteComponentProps} from "react-router-dom";
import {hubsRouteLinks} from "./hubsRouteLinks";
import {CreateHubResource} from "../../client/resources/createHubResource";
import {FormSection, required, Text} from "../../components/form";
import Sensitive, {ObfuscatedPlaceholder} from "../../components/Sensitive/Sensitive";
import HostedInstanceMultiSelect from "../../shared/HostedInstanceMultiSelect";
import HubConfigurationConfirmation from "../reefs/HubConfigurationConfirmation";
import {HostedInstanceLookupResource} from "../../client/resources/hostedInstanceLookupResource";
import {InstanceStatus} from "../../client/resources/instanceStatus";
import {createEmptySensitiveValue} from "../../shared/sensitiveValueHelper";

type Props = RouteComponentProps;

interface State extends OptionalFormBaseComponentState<CreateHubResource> {
    redirectTo?: string;
    useHubInstanceId: boolean;
    instances?: HostedInstanceLookupResource[];
    hubInstanceConfirmed: boolean;
}

class CreateHub extends OptionalFormBaseComponent<Props, State, CreateHubResource> {
    constructor(props: Props) {
        super(props);
        const model: CreateHubResource = {
            AzureAdClientSecret: createEmptySensitiveValue(),
            HubApiKey: createEmptySensitiveValue()
        };

        this.state = {
            model,
            cleanModel: cloneDeep(model),
            useHubInstanceId: false,
            hubInstanceConfirmed: false
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            this.setState(await this.loadData());
        });
    }

    async loadData() {
        const instances= await repository.HostedInstances.list({isInPool: "false", statuses:[InstanceStatus.Live, InstanceStatus.Provisioning]});
        return {instances: instances.Resources};
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo}/>;
        }

        return <FormPaperLayout
            title={`Create Hub`}
            breadcrumbTitle="Hubs"
            breadcrumbPath={hubsRouteLinks.root}
            busy={this.state.busy}
            errors={this.state.errors}
            model={this.state.model}
            cleanModel={this.state.cleanModel}
            saveButtonLabel={`Create new hub`}
            saveText="Hub Created"
            saveButtonBusyLabel={"Saving"}
            expandAllOnMount={true}
            onSaveClick={this.createHub}
            forceDisableFormSaveButton={!this.validateHubSelection()}
        >
            {this.getDetails()}
        </FormPaperLayout>;
    }

    private getDetails = () => {
        const model = this.state.model;

        return <>
            <FormSectionHeading title="Hub"/>
            <FormSection title="Hub type">
                <BooleanRadioButtonGroup value={this.state.useHubInstanceId} onChange={useHubInstanceId => this.setState({useHubInstanceId})}>
                    <BooleanRadioButton value={true} label="Choose instance" />
                    <BooleanRadioButton value={false} label="Enter URL (for an externally hosted hub)" />
                </BooleanRadioButtonGroup>
            </FormSection>
            {!this.state.useHubInstanceId && <><ExpandableFormSection
                errorKey="HubUrl"
                title="HubUrl"
                summary={model.HubUrl ? Summary.summary(model.HubUrl) : Summary.placeholder("No HubUrl")}
                help={<span>Enter the hub url for this reef.</span>}>
                <Text label="HubUrl"
                      value={model.HubUrl}
                      onChange={HubUrl => this.setState((prevState: State) => ({model: {...prevState.model, HubUrl}}))}
                      validate={required("Please enter the hub url to use for this reef")}
                      error={this.getFieldError("HubUrl")}
                />
            </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey="HubApiKey"
                    title="HubApiKey"
                    summary={model.HubApiKey && model.HubApiKey.HasValue ? Summary.summary(ObfuscatedPlaceholder) : Summary.placeholder("No HubApiKey")}
                    help={<span>Enter the hub api key for this reef.</span>}>
                    <Sensitive label="HubApiKey"
                               value={model.HubApiKey}
                               onChange={HubApiKey => this.setState((prevState: State) => ({model: {...prevState.model, HubApiKey}}))}
                               error={this.getFieldError("HubApiKey")}
                    />
                </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey="OidcIdentity"
                    title="OpenID Connect"
                    summary={Summary.placeholder("Authenticate to the Hub via OIDC")}
                    help={<span>Enter the details to authenticate to the Hub via OpenID Connect.</span>}>
                    <Text label="Audience"
                          value={model.OidcIdentityAudience}
                          onChange={OidcIdentityAudience => this.setState((prevState: State) => ({model: {...prevState.model, OidcIdentityAudience}}))}
                          error={this.getFieldError("OidcIdentityAudience")}
                    />
                    <Text label="ExternalId"
                          value={model.OidcIdentityExternalId}
                          onChange={OidcIdentityExternalId => this.setState((prevState: State) => ({model: {...prevState.model, OidcIdentityExternalId}}))}
                          error={this.getFieldError("OidcIdentityExternalId")}
                    />
                </ExpandableFormSection></>}
            {this.state.useHubInstanceId && this.state.instances &&
                <ExpandableFormSection
                    summary={Summary.placeholder("No hub instance")}
                    title="Hub instance"
                    errorKey="HubInstanceId">
                    <HostedInstanceMultiSelect
                        items={this.state.instances}
                        value={this.state.model.HubInstanceId ? [model.HubInstanceId] : []}
                        onChange={instanceIds => this.setModelHubInstanceId(instanceIds)}  />

                    <HubConfigurationConfirmation
                        useHubInstanceId={this.state.useHubInstanceId}
                        selectedHubDnsPrefix={this.getSelectedHubDnsPrefix()}
                        onChange={(isValid) => this.setState({hubInstanceConfirmed: isValid})}/>
                    
                    <Text label="Azure AD client ID" 
                          value={model.AzureAdClientId}
                          onChange={AzureAdClientId => this.setModelState({AzureAdClientId})}/>
                    
                    <Sensitive 
                        label="Azure AD client secret"
                        value={model.AzureAdClientSecret} 
                        onChange={AzureAdClientSecret => this.setModelState({AzureAdClientSecret})}/>
                </ExpandableFormSection>}
        </>;
    }

    private createHub = () => {
        return this.doBusyTask(async () => {
            await repository.Hubs.create(this.state.model);
            this.setState({redirectTo: hubsRouteLinks.root});
        });
    };

    private getSelectedHubDnsPrefix() {
        if (!this.state.instances || !this.state.model.HubInstanceId)
            return null;

        const selectedHubInstance = this.state.instances.find(i => i.Id == this.state.model.HubInstanceId);
        return selectedHubInstance.DnsPrefix;
    }

    private setModelHubInstanceId(instanceIds:string[])    {
        const instanceId = instanceIds.length > 0
            ? instanceIds[0]
            : null;

        this.setState((prevState: State) => ({model: {...prevState.model, HubInstanceId: instanceId}}));
    }

    private validateHubSelection(): boolean {
        return !this.state.useHubInstanceId ||
            HubConfigurationConfirmation.isExpectedHubDnsPrefix(this.getSelectedHubDnsPrefix()) ||
            this.state.hubInstanceConfirmed;
    }
}

export default CreateHub;
