import client from "client/client";
import {HubResource} from "../resources/hubResource";
import {CreateHubResource} from "../resources/createHubResource";
import {ChangeHubAzureAdResource} from "../resources/changeHubAzureAdResource";
import {ChangeHubApiKeyResource} from "../resources/changeHubApiKeyResource";
import {ChangeHubOidcIdentityResource} from "../resources/changeHubOidcIdentityResource";
import {CheckHubAccessResource} from "../resources/checkHubAccessResource";

export default class HubRepository {

    private rootUri = "/api/hubs";

    list(): Promise<HubResource[]> {
        return client.get(this.rootUri);
    }

    get(id: string): Promise<HubResource> {
        return client.get(`${this.rootUri}/{id}`, {id});
    }

    getForInstance(instanceId: string): Promise<HubResource> {
        return client.get(`${this.rootUri}/hosted-instance/{instanceId}`, {instanceId});
    }

    async tryGetForInstance(instanceId: string): Promise<HubResource | null> {
        try {
            return await this.getForInstance(instanceId);
        } catch (e) {
            if (e.StatusCode === 404) {
                return null;
            }
            throw e;
        }
    }

    create(resource: CreateHubResource): Promise<HubResource> {
        return client.post(`${this.rootUri}`, resource);
    }

    changeAzureAdConfiguration(id: string, resource: ChangeHubAzureAdResource):  Promise<ChangeHubAzureAdResource> {
        return client.put(`${this.rootUri}/{id}/change-azure-ad`, resource, {id});
    }

    changeApiKey(id: string, resource: ChangeHubApiKeyResource):  Promise<ChangeHubApiKeyResource> {
        return client.put(`${this.rootUri}/{id}/change-api-key`, resource, {id});
    }

    changeOidcIdentity(id: string, resource: ChangeHubOidcIdentityResource):  Promise<ChangeHubOidcIdentityResource> {
        return client.put(`${this.rootUri}/{id}/change-oidc-identity`, resource, {id});
    }

    checkAccess(id: string):  Promise<CheckHubAccessResource> {
        return client.post(`${this.rootUri}/{id}/check-access`, null, {id});
    }
    
    remove(resource: HubResource) {
        return client.del(`${this.rootUri}/{id}`, {args: {id: resource.Id}});
    }

    provisionSupportUser(id: string) {
        return client.post(`${this.rootUri}/{id}/provision-support-user`, null, {id});
    }

    reconfigure(resource: HubResource) {
        return client.post(`${this.rootUri}/{id}/reconfigure`,null, {id: resource.Id});
    }
}