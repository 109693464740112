import React, {Component} from "react";
import {css} from "@emotion/css";
import {themeTokens} from "@octopusdeploy/design-system-tokens";
import SimpleDataTable from "components/SimpleDataTable/SimpleDataTable";
import {AuditEntryResource} from "client/resources/auditEntryResource";
import {ChangeType} from "client/resources/changeType";
import {Fragment} from "components/Fragment";
import {FriendlyLocalTime} from "shared/FriendlyLocalTime";
import { UserIdentityResource } from "../../client/resources/userIdentityResource";
import ToolTip from "../../components/ToolTip";

const color = themeTokens.color;

const diffStyle = css`
    background: ${color.background.secondary.default};
`;

const insertStyle = css`
    background: ${color.background.success};
    border-bottom: 0.125rem solid ${color.border.success};
    text-decoration: none;
`;

const deleteStyle = css`
    background: ${color.background.danger};
    border-bottom: 0.125rem solid ${color.border.danger};
`;

const tooltipStyle = css`
    text-align: left;
`;

interface Props {
    auditEntries: AuditEntryResource[];
}

class DataTable extends SimpleDataTable<AuditEntryResource> {
}

class AuditDataTable extends Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render() {

        return <React.Fragment>
            <DataTable
                data={this.props.auditEntries}
                onRow={entry => this.buildRow(entry)}
                headerColumns={["ID", "Document ID", "Document Type", "Occured", "Operation", "User", "Changes"]}
                onEmpty={<div>No audit entries</div>}/>
        </React.Fragment>;
    }

    private buildRow(entry: AuditEntryResource) {

        return [
            entry.Id,
            entry.DocumentId,
            entry.DocumentType,
            <FriendlyLocalTime time={entry.Occured}/>,
            entry.Operation,
            this.userColumn(entry.User),
            <pre className={diffStyle} key={entry.Id}>
                {entry.Changes.map((change, changeIndex) => {
                    const breakLine = "\n\r";
                    const numberOfBreakLines =  (change.Text.match(/breakLine/g) || []).length;
                    const lines = change.Text.split(breakLine);
                    const endWithBreakLine = numberOfBreakLines === lines.length;

                    const content = lines.map((line, lineIndex) => {
                        return <Fragment key={lineIndex}>
                                {line}
                                {lineIndex === lines.length - 1 ? endWithBreakLine && <br/> : <br/>}
                            </Fragment>;
                    });

                    switch (change.Type) {
                        case ChangeType.Add: return <ins className={insertStyle} key={changeIndex}>{content}</ins>;
                        case ChangeType.Delete: return <del className={deleteStyle} key={changeIndex}>{content}</del>;
                        case ChangeType.NoChange: return <span key={changeIndex}>{content}</span>;
                    }
                })}
            </pre>
        ];
    }

    private userColumn(user: UserIdentityResource): JSX.Element {
        const toolTipContent = (
            <div className={tooltipStyle}>
                {user.DisplayName}<br/>
                {user.Email && <>Email: {user.Email}<br/></>}
                {user.IpAddress && <>IP: {user.IpAddress}<br/></>}
                {user.ConnectionIpAddress && <>Connection IP: {user.ConnectionIpAddress}<br/></>}
            </div>
        );
        return (
            <ToolTip content={toolTipContent}>
                {user.DisplayName}
            </ToolTip>
        );
    }
}

export default AuditDataTable;