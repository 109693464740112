import {Checkbox} from "@octopusdeploy/design-system-components";
import {DataBaseComponent, DataBaseComponentState} from "../../components/DataBaseComponent";
import {ReefResource} from "../../client/resources/reefResource";
import SaveDialogLayout from "../../components/DialogLayout/SaveDialogLayout";
import {required, Select, Text} from "../../components/form";
import repository from "../../client/repository";
import * as React from "react";
import {UpgradeKubernetesClustersRequest} from "client/resources/upgradeKubernetesClustersRequest";
import {VirtualMachineType} from "client/resources/virtualMachineType";
import {Number} from "shared/Number";
import Callout, {CalloutType} from "../../components/Callout";
import _ = require("lodash");
import {KubernetesClusterResource} from "../../client/resources/KubernetesClusterResource";

interface Props {
    reef: ReefResource;
    afterUpgrade: () => void;
}

interface State extends DataBaseComponentState {
    model: UpgradeKubernetesClustersRequest;
    mostRecentCluster: KubernetesClusterResource;
}

class UpgradeKubernetesClustersDialog extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        const mostRecentCluster = props.reef.KubernetesClusters[props.reef.KubernetesClusters.length - 1];

        this.state = {
            model: {
                ReplaceExistingKubernetesClusters: false,
                CreateKubernetesClusterRequest: {
                    AvailabilityZones: mostRecentCluster.AvailabilityZones,
                    ClusterVersion: mostRecentCluster.ClusterVersion,
                    NodeDiskSizeInGB: mostRecentCluster.NodeDiskSizeInGB,
                    NodeVmType: mostRecentCluster.NodeVmType,
                    HostedScriptsVersion: mostRecentCluster.HostedScriptsVersion,
                    NodePoolSnapshotResourceId: null
                }
            },
            mostRecentCluster,
        };
    }

    render() {
        const model = this.state.model;
        return <SaveDialogLayout title={`Upgrade Kubernetes Clusters`}
                                 onSaveClick={() => this.upgradeKubernetesClusters()}
                                 errors={this.state.errors}
                                 saveButtonLabel={`Upgrade`}
                                 busy={this.state.busy}>
            <Callout type={CalloutType.Warning} title="Warning">
                Please be mindful of the "Replace Existing Kubernetes Clusters?" option.<br />
                <br />
                This option should only be selected if you want to roll out a Hosted Scripts package with breaking changes or if you are changing any of the Kubernetes Cluster properties. <a href="https://github.com/OctopusDeploy/hosted-docs/blob/master/how-to/upgrade-a-k8s-cluster.md#overview">More information</a>.<br />
                <br />
                If you are unsure, please reach out in the <a rel="noopener" target="_blank" href="https://octopusdeploy.slack.com/archives/C01Q95KPRM4">#cloud-platform-requests</a> Slack channel.
            </Callout>
            <Text label="Hosted Scripts Version"
                  value={model.CreateKubernetesClusterRequest.HostedScriptsVersion}
                  onChange={value => this.modifyModelState({CreateKubernetesClusterRequest: {...model.CreateKubernetesClusterRequest, HostedScriptsVersion: value}})}
                  validate={required("Please enter the Hosted Scripts version for this Kubernetes Cluster")}
                  error={this.getFieldError("HostedScriptsVersion")}
            />
            <Text label="Kubernetes Cluster Version"
                  value={model.CreateKubernetesClusterRequest.ClusterVersion}
                  onChange={value => this.modifyModelState({CreateKubernetesClusterRequest: {...model.CreateKubernetesClusterRequest, ClusterVersion: value}})}
                  validate={required("Please enter the version for this Kubernetes Cluster")}
                  error={this.getFieldError("Kubernetes Cluster Version")}
            />
            <Select value={_.toString(model.CreateKubernetesClusterRequest.NodeVmType)}
                    onChange={(value: string) => this.modifyModelState({CreateKubernetesClusterRequest: {...model.CreateKubernetesClusterRequest, NodeVmType: (value as VirtualMachineType)}})}
                    items={Object.entries(VirtualMachineType).map(e => ({text: e[0], value: e[0]}))}
                    fieldName="Kubernetes Cluster Node Type"
                    hintText="The Virtual Machine type to be used by the Nodes in the Kubernetes Cluster."
            />
            <Number label="Kubernetes Cluster Node Disk Size in GB"
                    value={model.CreateKubernetesClusterRequest.NodeDiskSizeInGB}
                    min={0}
                    onChange={value => this.modifyModelState({CreateKubernetesClusterRequest: {...model.CreateKubernetesClusterRequest, NodeDiskSizeInGB: value}})}
                    validate={required("Please enter the Disk Size to be used by the Nodes in the Kubernetes Cluster")}
                    error={this.getFieldError("KubernetesNodeDiskSizeInGB")}
            />
            <Number label="Kubernetes Cluster Node Availability Zones"
                    value={model.CreateKubernetesClusterRequest.AvailabilityZones}
                    min={1}
                    max={2}
                    onChange={value => this.modifyModelState({CreateKubernetesClusterRequest: {...model.CreateKubernetesClusterRequest, AvailabilityZones: value}})}
                    validate={required("Please enter the number of Availability Zones to be used by the Nodes in the Kubernetes Cluster")}
                    error={this.getFieldError("AvailabilityZones")}
            />
            <Text label="NodePool Snapshot Resource Id"
                  value={model.CreateKubernetesClusterRequest.NodePoolSnapshotResourceId}
                  onChange={value => this.modifyModelState({CreateKubernetesClusterRequest: {...model.CreateKubernetesClusterRequest, NodePoolSnapshotResourceId: value}})}
                  error={this.getFieldError("NodePool Snapshot Resource Id")}
            />
            {this.isClusterReplaceRequired() && <Callout type={CalloutType.Information} title="Cluster Replacement Required">
                The requested Kubernetes cluster version differs from the Reef's current version, so a replacement must
                be performed.
            </Callout>}
            <Checkbox label="Replace Existing Kubernetes Clusters?"
                      disabled={this.isClusterReplaceRequired()}
                      value={this.isClusterReplaceRequired() || model.ReplaceExistingKubernetesClusters}
                      onChange={value => this.modifyModelState({ReplaceExistingKubernetesClusters: value})}
                      error={this.getFieldError("CreateNewKubernetesCluster")}
            />
        </SaveDialogLayout>;
    }

    private modifyModelState(upgradeResource: Partial<UpgradeKubernetesClustersRequest>): void {
        this.setState((prevState: State) => ({model: {...prevState.model, ...upgradeResource}}));
    }

    private isClusterReplaceRequired(): boolean {
        return this.state.model.CreateKubernetesClusterRequest.ClusterVersion !== this.state.mostRecentCluster.ClusterVersion;
    }

    private async upgradeKubernetesClusters(): Promise<boolean> {
        return await this.doBusyTask(async () => {
            const model = {
                ...this.state.model,
                ReplaceExistingKubernetesClusters: this.isClusterReplaceRequired() || this.state.model.ReplaceExistingKubernetesClusters
            };
            await repository.Reefs.upgradeKubernetesClusters(this.props.reef.Id, model);
            this.props.afterUpgrade();
        });
    }
}

export default UpgradeKubernetesClustersDialog;
