import * as React from "react";
import {LinearProgress} from "@octopusdeploy/design-system-components";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import session from "./session";
import repository from "./client/repository";
import ErrorPanel from "./components/ErrorPanel";
import DetailedError from "./utils/detailedError";

interface State {
    sessionEstablished: boolean;
    error?: Error;
}

export class SessionRoute extends React.Component<any, State> {

    constructor(props: any) {
        super(props);

        this.state = {
            sessionEstablished : false
        };
    }

    async componentDidMount() {

        try {

            if (session.isStarted()) {
                this.setState({sessionEstablished: true});
                return;
            }

            const me = repository.Authentication.me();
            const configuration = repository.Configuration.getExternalUrls();
            const featureFlags = repository.Configuration.getFeatureFlags();

            session.start(await me, await configuration, await featureFlags);
            this.setState({sessionEstablished: true});
        } catch (error) {
            if (error instanceof Error) {
                this.setState({error});
            }
        }
    }

    render() {
        const { component: Component, ...rest } = this.props;
        return (<ReloadableRoute {...rest} render={(props: any) => {
            return this.state.sessionEstablished ?
                <Component {...rest} /> :
                this.waitForSessionToBeEstablishedMessage();
        }
        } />);
    }

    private waitForSessionToBeEstablishedMessage() {
        return this.state.error ?
        this.displayError(this.state.error)
                :
        <div>
            <LinearProgress variant="indeterminate" show={true} />
            <p>Loading configuration data...</p>
        </div>;

    }

    private displayError(error: Error) {

        const isDetailedError = error instanceof DetailedError;
        const fullException = isDetailedError ? (error as DetailedError).FullException :  this.state.error.stack;
        const details = isDetailedError ? (error as DetailedError).Errors :  [error.message];

        return <ErrorPanel message="Configuration data can't be loaded. Make sure the backend process is running."
                    fullException={fullException}
                    details={details}
                    fullWidth={true}
        />;
    }
}