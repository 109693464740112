import {Checkbox} from "@octopusdeploy/design-system-components";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import repository from "client/repository";
import * as React from "react";
import ReefMultiSelect from "shared/ReefMultiSelect";
import {ReefResource} from "client/resources/reefResource";
import InternalRedirect from "components/Navigation/InternalRedirect";
import {backgroundTasksRouteLinks} from "areas/backgroundTasks/backgroundTasksRouteLinks";
import Callout, {CalloutType} from "components/Callout";
import {Text} from "../../../components/form";
import {
    HostedInstanceBulkRotateMasterKeyRequest
} from "../../../client/resources/HostedInstanceBulkRotateMasterKeyRequest";
import {AreYouReallySureConfirmation} from "./AreYouReallySureConfirmation";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {
}

interface State extends DataBaseComponentState {
    hostedInstanceIdsDelimitedByLine: string;
    reefs: Map<string, ReefResource>;
    rotationRequest: HostedInstanceBulkRotateMasterKeyRequest;
    redirectTo?: string;
    canBulkRotateMasterKey: boolean;
}

class BulkRotateMasterKeyDialog extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            hostedInstanceIdsDelimitedByLine: "",
            reefs: new Map<string, ReefResource>(),
            rotationRequest: {
                HostedInstanceIds: [],
                ReefIds: [],
                RotateDuringWeekends: false
            },
            canBulkRotateMasterKey: false,
        };
    }

    componentDidMount() {
        return this.doBusyTask(async () => {
            const reefs = repository.Reefs.map();

            this.setState({
                reefs: await reefs,
            });
        });
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo}/>;
        }

        return <SaveDialogLayout title={`Bulk Rotate Master Keys`}
                                 onSaveClick={() => this.bulkRotateMasterKeys()}
                                 errors={this.state.errors}
                                 saveButtonDisabled={!this.state.canBulkRotateMasterKey}
                                 busy={this.state.busy}>
            <AreYouReallySureConfirmation title={"Backups will become inaccessible"}
                                          warning={<>Database backups <strong>cannot be restored</strong> after bulk-rotating master keys. This will affect <strong>many Instances</strong>. Do not proceed if you require access to these backups. The <strong>Administrator</strong> role is required to rotate master keys.</>}
                                          requiredConfirmation={"many affected backups"}
                                          onChange={acknowledged => this.setState({canBulkRotateMasterKey: acknowledged})} />

            <Callout type={CalloutType.Information} title="Further Action">
                Instances excluded from the <strong>Participate in bulk Instance master key rotation</strong> Portal
                Feature Flag will not have their master keys rotated. These instances require manual rotation.
            </Callout>

            <Checkbox value={this.state.rotationRequest.RotateDuringWeekends}
                      onChange={v => this.updateRotationRequest({RotateDuringWeekends: v})}
                      label="Rotate keys during weekends"/>
            <p>
                If unticked, master keys will be rotated only on weekdays. If ticked, keys will also rotate on
                weekends.
            </p>

            <ReefMultiSelect
                items={this.state.reefs ? Array.from(this.state.reefs.values()) : []}
                value={this.state.rotationRequest.ReefIds}
                onChange={ReefIds => this.updateRotationRequest({ReefIds})}
            />
            <p>
                All instances will have their master keys rotated only if they are on the selected Reefs. If no Reef
                has been selected, every instance's keys will rotate.
            </p>

            <Text label="Hosted Instance IDs (one per line)"
                  value={this.state.hostedInstanceIdsDelimitedByLine}
                  onChange={ids => this.updateHostedInstanceIds(ids)}
                  multiLine={true}
                  hintText={"IDs, e.g. HostedInstances-1"}
            />
        </SaveDialogLayout>;
    }

    private updateRotationRequest(update: Partial<HostedInstanceBulkRotateMasterKeyRequest>) {
        return this.setState(prevState => ({rotationRequest: {...prevState.rotationRequest, ...update}}));
    }

    private updateHostedInstanceIds(idsDelimitedByLine: string) {
        return this.setState(prevState => ({
            hostedInstanceIdsDelimitedByLine: idsDelimitedByLine,
            rotationRequest: {
                ...prevState.rotationRequest,
                HostedInstanceIds: idsDelimitedByLine.split("\n").filter(id => id.trim() !== "")
            },
        }));
    }

    private async bulkRotateMasterKeys(): Promise<boolean> {
        return await this.doBusyTask(async () => {
            const backgroundTask = await repository.HostedInstances.bulkRotateMasterKeys(this.state.rotationRequest);
            this.setState({redirectTo: backgroundTasksRouteLinks.task(backgroundTask.Id).root})
        });
    }
}

export default BulkRotateMasterKeyDialog;
