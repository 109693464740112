import * as React from "react";
import { FeatureToggle, FeatureToggleScope } from "client/resources/featureFlagsResource";
import SimpleDataTable from "components/SimpleDataTable/SimpleDataTable";
import styles = require("./style.less");
import OpenDialogButton from "../../components/Dialog/OpenDialogButton";
import EditFeatureFlagScopeDialog from "./EditFeatureFlagScopeDialog";
import { HostedInstanceLookupResource } from "../../client/resources/hostedInstanceLookupResource";
import { ReefResource } from "../../client/resources/reefResource";
import SwitchWithConfirmation from "./SwitchWithConfirmation";

class DataTable extends SimpleDataTable<FeatureToggle> {
}

interface Props {
    featureFlags: FeatureToggle[];
    editFeatureFlag: (featureFlag: FeatureToggle) => Promise<void>;
    editFeatureFlagScope: (id: string, scope: FeatureToggleScope) => Promise<void>;
    instances: HostedInstanceLookupResource[];
    reefs: ReefResource[];
}

class FeatureFlagsDataTable extends React.Component<Props> {
    render() {
        return (
            <DataTable
                data={this.props.featureFlags}
                onRow={this.buildRow}
                headerColumns={["Feature Name", "Enabled", "Scope", "Min. version"]}
                rowColumnClassName={styles.dataTableRowColumn}
            />
        );
    }

    private buildRow = (featureFlag: FeatureToggle) => {
        const onToggle = (isInputChecked: boolean) => {
            return this.props.editFeatureFlag({
                ...featureFlag,
                IsEnabled: isInputChecked
            });
        };

        const columns = [
            featureFlag.Name,
            <SwitchWithConfirmation
                value={featureFlag.IsEnabled}
                onChange={onToggle}
                settingName={featureFlag.Name}
                noun="Feature Flag" />,
            this.renderScope(featureFlag),
            featureFlag.AppliesTo.MinimumVersion
        ];

        if (featureFlag.Granularity !== "Global") {
            columns.push(<OpenDialogButton label={"Edit scope"}>
                <EditFeatureFlagScopeDialog
                    featureFlag={featureFlag}
                    editScope={this.props.editFeatureFlagScope}
                    instances={this.props.instances}
                    reefs={this.props.reefs} />
            </OpenDialogButton>);
        }
        return columns;
    }

    private renderScope = (featureFlag: FeatureToggle) => {
        const exclusionCount = featureFlag.AppliesTo.ExcludedInstanceIds.length;
        const exclusionString = exclusionCount > 0 ? `| -${exclusionCount} instance(s)` : "";
        switch (featureFlag.AppliesTo.Scope) {
            case "Global":
                return `Global ${exclusionString}`;
            case "Reef":
                return `Reef (${featureFlag.AppliesTo.ReefIds.length}) ${exclusionString}`;
            case "Instance":
                return `Instance (${featureFlag.AppliesTo.InstanceIds.length})`;
        }
    }

}

export default FeatureFlagsDataTable;