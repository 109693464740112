import * as React from "react";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import repository from "client/repository";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import {ReefResource} from "client/resources/reefResource";
import {Callout, CalloutType} from "../../components/Callout";
import {HubResource} from "../../client/resources/hubResource";
import Select from "components/form/Select/Select";
import Sensitive from "../../components/Sensitive/Sensitive";
import {SensitiveValue} from "../../client/resources/sensitiveValue";
import {createEmptySensitiveValue} from "../../shared/sensitiveValueHelper";
import {Text} from "../../components/form";

interface Props {
    hub: HubResource
    onChange: (hub: Partial<HubResource>) => void;
}

interface State extends DataBaseComponentState {
    apiKey: SensitiveValue;
}

class ChangeHubApiKeyDialog extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            apiKey: createEmptySensitiveValue()
        };
    }

    render() {
        return <SaveDialogLayout title={"Hub API key"}
                                 onSaveClick={() => this.changeConfiguration()}
                                 errors={this.state.errors}
                                 saveButtonDisabled={!this.state.apiKey.HasValue}
                                 busy={this.state.busy}>
            <Sensitive label={`Hub API key (${this.props.hub.DisplayName})`}
                  value={this.state.apiKey}
                  onChange={apiKey => this.setState({apiKey})}/>
        </SaveDialogLayout>;
    }

    private async changeConfiguration(): Promise<boolean> {
        return await this.doBusyTask(async () => {
            const request = { HubApiKey: this.state.apiKey };

            await repository.Hubs.changeApiKey(this.props.hub.Id, request);
            this.props.onChange({});
        });
    }


}

export default ChangeHubApiKeyDialog;