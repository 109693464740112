import * as React from "react";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import repository from "client/repository";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import {ReefResource} from "client/resources/reefResource";
import {Callout, CalloutType} from "../../components/Callout";
import {HubResource} from "../../client/resources/hubResource";
import Select from "components/form/Select/Select";
import Sensitive from "../../components/Sensitive/Sensitive";
import {SensitiveValue} from "../../client/resources/sensitiveValue";
import {createEmptySensitiveValue} from "../../shared/sensitiveValueHelper";
import {Text} from "../../components/form";

interface Props {
    hub: HubResource
    onChange: (hub: Partial<HubResource>) => void;
}

interface State extends DataBaseComponentState {
    oidcIdentityAudience?: string;
    oidcIdentityExternalId?: string;
}

class ChangeHubOidcIdentityDialog extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            oidcIdentityAudience: props.hub.OidcIdentityAudience,
            oidcIdentityExternalId: props.hub.OidcIdentityExternalId,
        };
    }

    render() {
        return <SaveDialogLayout title={`Hub OIDC Identity (${this.props.hub.DisplayName})`}
                                 onSaveClick={() => this.changeConfiguration()}
                                 errors={this.state.errors}
                                 busy={this.state.busy}>
            <Text label="Audience"
                  value={this.state.oidcIdentityAudience}
                  onChange={oidcIdentityAudience => this.setState({oidcIdentityAudience})}/>
            <Text label="External ID"
                  value={this.state.oidcIdentityExternalId}
                  onChange={oidcIdentityExternalId => this.setState({oidcIdentityExternalId })}/>
        </SaveDialogLayout>;
    }

    private async changeConfiguration(): Promise<boolean> {
        return await this.doBusyTask(async () => {
            const request = {
                OidcIdentityAudience: this.state.oidcIdentityAudience,
                OidcIdentityExternalId: this.state.oidcIdentityExternalId
            };

            await repository.Hubs.changeOidcIdentity(this.props.hub.Id, request);
            this.props.onChange({
                OidcIdentityAudience: this.state.oidcIdentityAudience,
                OidcIdentityExternalId: this.state.oidcIdentityExternalId
            });
        });
    }


}

export default ChangeHubOidcIdentityDialog;